
  import { defineComponent } from "vue";
  import { courseModel} from "@/models/WorkspaceModel";
  import WorkspaceCourseApi from "@/apis/WorkspaceCourseApi";


  // import PeiiCommodity from '@/components/CommodityList.vue'

  export default defineComponent({
    name: "EditFreeCourse",
    components: {
    },
    data() {
      return {
        commodityList: [] as any[],
        freeCourse: new Array<courseModel>(),
        isCreated: false,
        isHoldingCurrentComodity: false,
        // isQuiz: false,
        fourQuiz : [] as any[],
        readyBtn : false,
        addToShoppingCartStatus: false,
        cookie: this.$cookies,
        treeData: null as any,
        searchString: "",
        courseFolder: new Array<courseModel>(),
        pages:{
            pageNumber: 1, // 目前頁碼，從1開始
            pageSize: 10, // 一頁幾筆，任意>0
            totalPages: 3, // 總頁面數，從0開始
        },
        freeCourseIds: [] as any[],
      };
    },
    props: {

    },
    computed: {},
    methods: {
        clearKeyword(){
            console.log("Debug: clearKeyword");
            this.searchString = "";
            this.searchByname();
        },
        async searchByname(){
            this.$store.commit('updateLoading', true);
            try{
                const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName(this.searchString ,1, "name")));
                this.courseFolder = courseQueryResult.results.results;
                this.pages.totalPages = courseQueryResult.results.totalPages;
                this.pages.pageNumber = courseQueryResult.results.pageNumber;
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit('updateLoading', false);
            }
        },
        async onPageChange(newPage:number){
            console.log("Debug: onPageChange newPage= ", newPage);
            console.log("Debug: onPageChange buttonSearch keyword= ", this.searchString);
            if(newPage < 1)  newPage = 1;
            else if(newPage > this.pages.totalPages)    newPage = this.pages.totalPages
            this.$store.commit('updateLoading', true);
            try{
                const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName(this.searchString ,newPage,"name")));
                this.courseFolder = courseQueryResult.results.results;
                this.pages.totalPages = courseQueryResult.results.totalPages;
                this.pages.pageNumber = courseQueryResult.results.pageNumber;
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit('updateLoading', false);
            }
        },
        selectCourse(uuid: string) {
            this.$bvModal.msgBoxConfirm('确定要設定此課程為免費課程吗?',{
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: '确定',
            cancelTitle: '取消',
            footerClass: 'p-2',
            hideHeaderClose: true,
            centered: true
          })
            .then(async value => {
              if(value){
                console.log("uuid = " + uuid)
                this.freeCourseIds = []
                for(let j=0; j<this.freeCourse.length; j++){
                  this.freeCourseIds.push(this.freeCourse[j].uuid)
                }
                this.freeCourseIds.push(uuid)
                console.log("freeCourseIds = " + this.freeCourseIds)
                this.$store.commit('updateLoading', true);
                try{
                    const upDateFreeCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateFreeCourses(this.freeCourseIds)));
                    this.$router.push({
                      name: "FreeCourseWorkspace",
                  });
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
              }
            })
            .catch(err => {
              // An error occurred
            })
        },
        searchList() {
            // let result = new Array<searchCommodity>();
            let result = [] as any;
            this.commodityList.forEach((element) => {
                let listName = '';
                listName = element.name;
                let inputName = this.trimSearchString();
                const indexOf = listName.toUpperCase().indexOf(inputName);
                if (indexOf >= 0) {
                let elementObject = { uuid: "", name: "" , imageUrl:"", isSelected: false , saveIndex: 0};
                elementObject.uuid = element.uuid;
                elementObject.name = element.name;
                elementObject.imageUrl = element.imageUrl;
                elementObject.isSelected = element.isSelected;
                elementObject.saveIndex = element.saveIndex;
                result.push(elementObject);
                }
            });
            return result;
        },
        trimSearchString(){
            // 修掉輸入的空白
            const string = this.searchString.trim();
            return string.toUpperCase();
        },
        filteredMethodList(){
            // if (this.trimSearchString()!=='') {
            //     this.onPageChange(this.pages.pageNumber);
            // }
            var commodity = this.courseFolder
            for(let i=0; i<this.courseFolder.length; i++){
                for(let j=0; j<this.freeCourse.length; j++){
                    if(this.courseFolder[i].uuid == this.freeCourse[j].uuid){
                        const index = commodity.findIndex(item => item.uuid === this.courseFolder[i].uuid);
                        if (index !== -1) {
                            commodity.splice(index, 1);
                        }
                    }
                }
            }
            return commodity;
        },
    },
    async created() {
        this.freeCourse = this.$store.state.currentFreeCourse
        this.$store.commit('updateLoading', true);
        try{
            const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName("" ,this.pages.pageNumber, "name")));
            this.courseFolder = courseQueryResult.results.results;
            this.pages.totalPages = courseQueryResult.results.totalPages;
            this.pages.pageNumber = courseQueryResult.results.pageNumber;
        }
        catch(e){
            console.log(e);
        }
        finally{
            this.$store.commit('updateLoading', false);
        }
    },
  });
  